<div class="leaflet-control-search leaflet-control" id="searchControl">
    <a *ngIf="!showSearchField"
        class="leaflet-control-search-button leaflet-control-pill"
        (click)="toggleSearchField()"
        fxLayoutAlign="start center"
        >       
        <zui-icon icon="place"></zui-icon>
        <span class="leaflet-control-search-button-label" fxShow.xs="false">{{ translateService.get(translations.map.searchControl.places) | async | translate }}</span>
    </a>

    <div *ngIf="showSearchField" class="search-container">
        <div class="search-form" fxLayoutAlign="end center">
            <zui-icon icon="place"></zui-icon>
            <mat-form-field floatLabel="never">
                <input matInput  [placeholder]="translateService.get(translations.map.searchControl.placeHolder) | async | translate" (keyup)="onSearch($event)" [(ngModel)]="searchKey">
            </mat-form-field>
            <zui-icon icon="close" (click)="toggleSearchField()"></zui-icon>
        </div>

    <div *ngIf="(loadComplete()|async ) !== initial" class="search-results">

        <!-- POIs -->
        <div class="search-category">
            <div class="category-heading">{{ translateService.get(translations.map.searchControl.poiResults) | async | translate }}</div>
                <ng-container *ngIf="(loadComplete()|async ) === loading">
                    <div *ngFor='let i of [].constructor(skeletonMaxCount);'>
                        <app-address-list-item-skeleton></app-address-list-item-skeleton>
                    </div>
                </ng-container>

                <ng-container *ngIf="(loadComplete()|async ) === loadSuccessful">
                    <div *ngFor="let item of pois" class="result-label">
                        <a (click)="goToLocation(item, false)">{{item?.title}}</a>
                    </div>
                    <div *ngIf="pois?.length === 0" class="no-results-text">{{ translateService.get(translations.map.searchControl.noResults) | async | translate }}</div>
                </ng-container>
        </div>

        <!-- Addresses -->
        <div class="search-category">
            <div class="category-heading">{{ translateService.get(translations.map.searchControl.addressResults) | async | translate }}</div>
                <ng-container *ngIf="(loadComplete()|async ) === loading">
                    <div *ngFor='let i of [].constructor(skeletonMaxCount);'>
                        <app-address-list-item-skeleton></app-address-list-item-skeleton>
                    </div>
                </ng-container>

                <ng-container *ngIf="(loadComplete()|async ) === loadSuccessful">
                    <div *ngFor="let item of addresses;" class="result-label">
                        <a (click)="goToLocation(item)">{{item?.address?.label}}</a>
                    </div>
                    <div *ngIf="addresses?.length === 0" class="no-results-text">{{ translateService.get(translations.map.searchControl.noResults) | async | translate }}</div>
                </ng-container>
        </div>

        <!-- Zones -->
        <div class="search-category">
            <div class="category-heading">{{ translateService.get(translations.map.searchControl.zoneResults) | async | translate }}</div>
                <ng-container *ngIf="!zonesLoaded else zoneslist">
                    <div *ngFor='let i of [].constructor(skeletonMaxCount);'>
                        <app-address-list-item-skeleton></app-address-list-item-skeleton>
                    </div>
                </ng-container>

                <ng-template #zoneslist>
                    <div *ngFor="let zone of zones;" class="result-label">
                        <a (click)="goToZone(zone)">{{zone?.name}}</a>
                    </div>
                    <div *ngIf="zones?.length === 0" class="no-results-text">{{ translateService.get(translations.map.searchControl.noResults) | async | translate }}</div>
                </ng-template>
            </div>
        </div>
    </div>
</div>