import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ViewableAsset } from '@app/modules/location/models/viewable-asset.model';
import { environment } from '@environments/environment';
import { defer, Observable, of } from 'rxjs';
import { Trips } from '@app/modules/location/models/trip.model';
import { mergeMap } from 'rxjs/operators';
import { DataDogService } from '@app/services/data-dog.service';

@Injectable({
  providedIn: 'root'
})
export class RecentPathService {
  constructor(private http: HttpClient, private ddLogger: DataDogService) {}

  fetchRecentPath(selectedAsset: ViewableAsset, startTime: Date, endTime: Date): Observable<Trips> {
    let returnPath = undefined;
    const ddLogAttributes = {
      pathGpsPoints: 0,
      pathLoadTime: 0
    };
    const _getAllResults = (url, params) =>
      defer(() =>
        this.http.get(url, { params, observe: 'response' }).pipe(
          mergeMap((result: HttpResponse<Trips>) => {
            if (!returnPath) {
              returnPath = result.body;
            } else {
              returnPath.pointData.features = returnPath.pointData.features.concat(result.body.pointData.features);
            }

            if (returnPath.pointData.features.length > 0) {
              ddLogAttributes.pathLoadTime = window.performance.now() - pathLoadStartTime;
              ddLogAttributes.pathGpsPoints = returnPath.pointData.features.length;
              this.ddLogger.log('Live Maps Path Stats', ddLogAttributes);
            }
            return of(returnPath);
          })
        )
      );

    const url = `${environment.pathApiBase.url}/paths/${selectedAsset.assetId}`;
    const firstPageParams = {
      startTime: startTime.toISOString(),
      endTime: endTime.toISOString(),
      companyId: selectedAsset.companyId,
      applicationId: environment.auth.applicationId
    };
    const params: HttpParams = new HttpParams({ fromObject: firstPageParams as any });

    const pathLoadStartTime = window.performance.now();
    return _getAllResults(url, params);
  }
}
