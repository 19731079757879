import { Injectable, OnDestroy } from '@angular/core';
import { Translations } from '@app/core/services/i18n/translations.service';
import { ResourceLoadState } from '@app/store/filters/models/resource-load.state';
import {
  AssetFilterSelectionModel,
  ASSET_FILTER_KEY_NAME,
  DropdownOptionsObject,
  FILTER_TYPE,
  SearchableDropdownModel,
  ZONE_FILTER_KEY_NAME,
  ZuiAssetFilterModel,
  ZuiZoneFilterModel
} from '@zonar-ui/filter';
import { TranslateService } from '@zonar-ui/i18n';
import { BehaviorSubject, Observable, of, Subject, take } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import {
  FiltersBarSelections,
  FiltersState,
  initialFiltersState,
  SortAttribute,
  SortOrder
} from '@app/services/filters.model';
import { CurrentCompanyService } from './current-company.service';
import { isBoolean } from '@app/modules/shared/utilities/utilities';
import { ZonesService } from '@app/modules/zones/services/zones.service';
import { Resources, Zone } from '@app/modules/zones/zones.model';
import { LeafletZoneService } from '@app/modules/zones/services/leaflet-zone.service';

@Injectable({
  providedIn: 'root'
})
export class FiltersService implements OnDestroy {
  private translated;
  private onDestroy$ = new Subject<void>();

  private filtersState = new BehaviorSubject<FiltersState>(initialFiltersState);

  constructor(
    private translateService: TranslateService,
    private translations: Translations,
    private currentCompanyService: CurrentCompanyService,
    private zonesService: ZonesService,
    private leafletZoneService: LeafletZoneService
  ) {
    this.translations.translationsLoadState
      .pipe(
        takeUntil(this.onDestroy$),
        filter(loadstate => loadstate === ResourceLoadState.LOAD_SUCCESSFUL)
      )
      .subscribe(() => {
        this.translated = this.translateService.instant([
          this.translations.filters.filterNames.assetid,
          this.translations.filters.powerStatus.poweredOffAssets,
          this.translations.filters.powerStatus.poweredOnAssets,
          this.translations.filters.filterNames.powerStatus,
          this.translations.filters.filterNames.zone
        ]);
      });

    this.currentCompanyService
      .getCurrentCompanyId()
      .pipe(
        filter(id => id != null),
        takeUntil(this.onDestroy$)
      )
      .subscribe(id => {
        // when company changes, reset all filters state
        this.filtersState.next({ ...initialFiltersState, companyId: id } as FiltersState);
      });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  getFiltersState(): Observable<FiltersState> {
    return this.filtersState;
  }

  setStateFromFiltersBar(selections: FiltersBarSelections) {
    if (selections) {
      let newFiltersState: FiltersState = { ...this.filtersState.getValue() };
      for (const filter in selections) {
        const val = selections[filter];
        switch (filter) {
          case ASSET_FILTER_KEY_NAME:
            const assetFilterSelection = val as AssetFilterSelectionModel;
            // if all assets option is selected, default to no explicit division or asset id filters
            if (val.isAllSelected) {
              newFiltersState.divisionIds = [];
              newFiltersState.assetIds = [];
            } else {
              newFiltersState.divisionIds = assetFilterSelection.selected.divisionIds;
              newFiltersState.assetIds = assetFilterSelection.selected.assetIds;
            }
            break;
          case ZONE_FILTER_KEY_NAME:
            if (val && val.id) {
              // since we need to call zones api to get geometry, this method updates geojson portion of filtersState async
              this.setGeojsonStateByZoneId(val.id);
              // if zones layer isn't enabled, enable now
              this.leafletZoneService
                .getZonesEnabled()
                .pipe(take(1))
                .subscribe(ze => {
                  if (!ze) this.leafletZoneService.addZonesToMap();
                });
            } else {
              newFiltersState.geojson = null;
            }
            break;
          case 'power_status':
            if (val.isAllSelected) {
              newFiltersState.powerOn = null; // if both selected, all assets
            } else if (isBoolean(val.selected[0])) {
              // get 0th if only one selected
              newFiltersState.powerOn = val.selected[0];
            } else {
              newFiltersState.powerOn = null;
            }
            break;
        }
      }
      this.filtersState.next(newFiltersState);
    }
  }

  setGeojsonStateByZoneId(zoneId: string) {
    let newFiltersState: FiltersState = { ...this.filtersState.getValue() };
    this.zonesService.getResourceById(zoneId, Resources.ZONES).subscribe((zone: Zone) => {
      newFiltersState.geojson = JSON.stringify(
        (zone.geometry.features.find(f => f.properties.name == 'GEOMETRY') || zone.geometry.features[0]).geometry
      );
      this.filtersState.next(newFiltersState);
    });
  }

  setSortingState(sortAttribute: SortAttribute, sortOrder: SortOrder) {
    this.filtersState.next({
      ...this.filtersState.getValue(),
      sortAttribute: sortAttribute,
      sortOrder: sortOrder
    } as FiltersState);
  }

  getFilterList(): (ZuiAssetFilterModel | ZuiZoneFilterModel | SearchableDropdownModel)[] {
    const filterList: (ZuiAssetFilterModel | ZuiZoneFilterModel | SearchableDropdownModel)[] = [
      // TODO: asset/driver search will go first, once added to filterbar package
      {
        type: FILTER_TYPE.ASSET,
        options: {
          label: this.translated[this.translations.filters.filterNames.assetid],
          // selectedAssetsInput: [], // ZTT-3666 TODO: this is in emissions app - does it affect filter bootstrap?
          paramName: ['division_id', 'asset_id']
        }
      } as ZuiAssetFilterModel,
      // TODO: this is where the asset properties filter goes
      {
        type: FILTER_TYPE.ZONE_FILTER,
        options: {
          paramName: 'zone_id',
          uniqueDropdownName: this.translated[this.translations.filters.filterNames.zone]
        }
      } as ZuiZoneFilterModel,
      {
        type: FILTER_TYPE.SEARCHABLE_DROPDOWN,
        options: {
          label: this.translated[this.translations.filters.filterNames.powerStatus],
          data: of([
            {
              title: this.translated[this.translations.filters.powerStatus.poweredOffAssets],
              value: false
            },
            {
              title: this.translated[this.translations.filters.powerStatus.poweredOnAssets],
              value: true
            }
          ] as DropdownOptionsObject[]),
          isMultiple: true,
          fgControlName: 'powerStatus', // TBD: what does formgroup in the PL package do? this config is not optional, but we may not use formgroup in our app
          valueType: null, // This appears to be involved in url pre-selection logic, revisit
          blueCheckmarks: true,
          inputParams: [],
          enableAllOptions: true,
          paramName: 'power_status' // aside from param for pre-fill, this also determines selection key when updating state
        }
      } as SearchableDropdownModel
    ];

    return filterList;
  }
}
