import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@zonar-ui/i18n';
import { Translations } from '@app/core/services/i18n/translations.service';
import { EventTrackingFacade, TrackedEvents } from '@app/modules/location/facade/event-tracking.facade';

@Component({
  selector: 'app-map-options-menu',
  templateUrl: './map-options-menu.component.html',
  styleUrls: ['./map-options-menu.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MapOptionsMenuComponent {
  constructor(
    public translateService: TranslateService,
    public translations: Translations,
    public pendo: EventTrackingFacade
  ) {}

  @Input() layer: string = 'NORMAL_DAY';
  @Output() layerChange: EventEmitter<string> = new EventEmitter();

  @Input() checkboxesVisible: boolean = false;

  @Input() clusterEnabled: boolean = false;
  @Output() clusterEnabledChange: EventEmitter<boolean> = new EventEmitter();

  @Input() incidentsEnabled: boolean = false;
  @Output() incidentsEnabledChange: EventEmitter<boolean> = new EventEmitter();

  @Input() trafficEnabled: boolean = false;
  @Output() trafficEnabledChange: EventEmitter<boolean> = new EventEmitter();

  @Input() zonesEnabled: boolean = false;
  @Output() zonesEnabledChange: EventEmitter<boolean> = new EventEmitter();

  @Input() classicLayerSelected: boolean = false;
  @Input() satelliteLayerSelected: boolean = false;

  onLayerClick(layer: string) {
    this.layerChange.emit(layer);
    this.layer = layer;
  }

  onClusterAssetsClick(event) {
    this.clusterEnabledChange.emit(event.checked);
  }

  onRoadIncidentsClick(event) {
    this.incidentsEnabledChange.emit(event.checked);
  }

  onTrafficClick(event) {
    this.trafficEnabledChange.emit(event.checked);
  }

  onShowZonesClick(event) {
    this.zonesEnabledChange.emit(event.checked);
    const pendoEventName = event.checked ? TrackedEvents.ZONES_ENABLED_ON_CLICK : TrackedEvents.ZONES_DISABLED_ON_CLICK;
    this._sendZonesToPendo(pendoEventName);
  }

  // wrap in private method to avoid test error where it cannot find trackEvent as a property of EventTrackingFacade
  _sendZonesToPendo(pendoEventName) {
    this.pendo.trackEvent(pendoEventName);
  }
}
