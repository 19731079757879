import { Injectable, OnDestroy } from '@angular/core';

// third party imports
import { Observable, Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import { LatLng, Map } from 'leaflet';
import { filter, first, takeUntil } from 'rxjs/operators';

// store
import { AppState } from '@app/store';
// store selectors
import {
  selectAllAssets,
  selectAssetCount,
  selectAssetParams,
  selectAssetsLoadError,
  selectAssetsLoadState,
  selectChosenAsset,
  selectIsAssetSelected,
  selectNearbyAssets,
  selectNearbyAssetsEnabled,
  selectNearbyAssetsLoadState,
  selectPathSummary,
  selectPathTrips,
  selectRecentPath,
  selectRecentPathLoadState,
  selectRecentPathStartTime,
  selectReverseGeocode,
  selectReverseGeocodeStatus,
  selectSelectedPathSegment
} from '@app/store/asset/selectors/assets.selectors';
import { selectAllFilters, selectCurrentFilter } from '@app/store/filters/selectors/filters.selectors';
import {
  selectFilterChipLabels,
  selectShowFilterChipsForLimitedUser
} from '@app/store/filters/selectors/filter-chips.selectors';
import {
  selectCompaniesLoadingState,
  selectCompaniesSearchFilterOptions,
  selectDivisionsSearchFilterOptions,
  selectLocationsSearchFilterOptions
} from '@app/store/filters/selectors/search-filters.selector';
import { selectAllPermissions, selectReadCompaniesPerm } from '@app/store/permissions/selectors/permissions.selectors';
import {
  selectViewContext,
  selectViewPaneOpen,
  selectViewSubContext
} from '@app/store/layout/selectors/layout.selectors';

// store actions
import {
  clearSearchFiltersLocations,
  getCompaniesFailure,
  getSearchFiltersDivisions,
  setLimitedUserCompanyAndDivisions,
  setLocationsForLimitedUser
} from '@app/store/filters/actions/search-filters.actions';
import {
  applyFilters,
  applySorting,
  clearFilters,
  removeFilter,
  setCompany,
  setCurrentCompany,
  setDivisions,
  setLocations
} from '@app/store/filters/actions/filters.actions';
import { loadPermissions } from '@app/store/permissions/actions/permissions.actions';
import { selectPlacesLoadState, selectPlacesResults } from '@app/store/places/selectors/places.selectors';

import {
  clearNearbyAssets,
  clearRecentPath,
  clearRecentPathSelectedSegment,
  loadAssets,
  loadAssetsFailure,
  loadNearbyAssets,
  loadRecentPath,
  loadSelectedAsset,
  setRecentPathSelectedSegment,
  setRecentPathStartTime,
  startAssetsPolling,
  stopAssetsPolling,
  loadSelectedAssetSuccess,
  loadSelectedAssetFailure,
  clearSelectedAsset
} from '@app/store/asset/actions/assets.actions';
import {
  setViewContext,
  setViewFiltersOpen,
  setViewPaneOpen,
  setViewSubContext
} from '@app/store/layout/actions/layout.actions';
import { clearPlacesResult, loadPlacesResult } from '@app/store/places/actions/places.actions';
import { clearDriverProfile } from '@app/store/driver/actions/driver.actions';

// services
import { LeafletService } from '@app/modules/location/services/leaflet.service';
import { ClusterLayerConfigService } from '@app/modules/location/services/leaflet-layer-configs/cluster-layer-config.service';
import { PlatformFacade } from '@app/modules/platform/facade/platform.facade';

// models
import { DropdownOption } from '@zonar-ui/searchable-dropdown';
import { EntityResource, Filter, FiltersState as OldFiltersState } from '@app/store/filters/models/filters.model';
import { ResourceLoadState } from '@app/store/filters/models/resource-load.state';
import { DetailsSubcontext, ViewContext } from '@app/store/layout/reducers/layout.reducer';
import { ViewableAsset } from '@app/modules/location/models/viewable-asset.model';
import { AssetsParams, Asset } from '@app/modules/location-client/location-api.models';
import { ReverseGeocoderService } from '@app/modules/reverse-geocoder/services/reverse-geocoder.service';
import { AutoSuggestionResponse, AutoSuggestRequest, Position } from '../models/auto-suggestion.model';
import { Zone } from '@app/modules/zones/zones.model';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class LocationFacade implements OnDestroy {
  private onDestroy$ = new Subject();
  viewSubContext: DetailsSubcontext;

  constructor(
    private leafletService: LeafletService,
    private clusterLayerConfigService: ClusterLayerConfigService,
    private platformFacade: PlatformFacade,
    private store: Store<AppState>,
    private geocode: ReverseGeocoderService,
    private router: Router
  ) {
    this.clusterLayerConfigService.pinClickAsset$
      .pipe(
        filter(asset => Boolean(asset)),
        takeUntil(this.onDestroy$)
      )
      .subscribe(asset => {
        // Workaround until tiles are in place and the cluster service goes away
        this.router.navigateByUrl(`/assets/${asset.assetId}/live`);
      });

    this.getSelectedViewSubContext()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(c => (this.viewSubContext = c));
  }

  ngOnDestroy() {
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }

  // ===== Platform Facade =====
  getOrientation() {
    return this.platformFacade.getOrientation();
  }

  getIsMobile() {
    return this.platformFacade.getIsMobile();
  }

  // ===== Leaflet Service =====
  receiveMap($event: Map) {
    this.leafletService.receiveMap($event);
  }

  setHover($event) {
    this.leafletService.setHighlightMarker($event);
  }

  clearHover() {
    this.leafletService.clearHighlightMarker();
  }

  refreshMap(assets, nearbyAssets, selectedAsset?) {
    this.leafletService.refreshMap(assets, nearbyAssets, selectedAsset);
  }

  zoomToAssets(assets) {
    this.leafletService.zoomToAssets(assets);
  }

  zoomToZone(zone: Zone) {
    this.leafletService.zoomToZone(zone);
  }

  showClusterLayer() {
    this.leafletService.showClusterLayer();
  }

  clearClusterLayer() {
    this.leafletService.clearClusterLayer();
  }

  showSelectedLocation(position: Position) {
    this.leafletService.showSelectedLocation(position);
  }

  getMapCenter(): LatLng {
    return this.leafletService.getMapCenter();
  }

  clearSelectedLocationMarker() {
    this.leafletService.clearSelectedLocationMarker();
  }

  restoreLiveMap() {
    this.leafletService.restoreLiveMap();
  }

  // ===== Store Actions =====
  applyFilters(filterArgs) {
    this.store.dispatch(applyFilters({ filterArgs: filterArgs }));
  }

  applySorting(sorting) {
    this.store.dispatch(applySorting({ sorting }));
  }

  clearFilters() {
    this.store.dispatch(clearFilters());
  }

  loadPermissions() {
    this.store.dispatch(loadPermissions());
  }

  removeFilter(name) {
    this.store.dispatch(removeFilter({ filter: name }));
  }

  startAssetsPolling() {
    this.store.dispatch(startAssetsPolling());
  }

  setDivisionFilter(divisions: string[]): void {
    this.store.dispatch(setDivisions({ divisions }));
  }

  setLocationFilter(locations: string[]): void {
    this.store.dispatch(setLocations({ locations }));
  }

  getDivisionsByCompanyId(companyId: string): void {
    this.store.dispatch(getSearchFiltersDivisions({ companyId }));
  }

  stopAssetsPolling(): void {
    this.store.dispatch(stopAssetsPolling());
  }

  setSelectedAsset(asset: ViewableAsset): void {
    this.store.dispatch(clearDriverProfile());
    this.store.dispatch(clearRecentPath());
    this.store.dispatch(loadSelectedAsset({ selectedAsset: asset }));
  }

  setViewContext(context: ViewContext) {
    this.store.dispatch(setViewContext({ context }));
  }

  setViewSubContext(subContext: DetailsSubcontext) {
    this.store.dispatch(setViewSubContext({ subContext }));
    if (subContext === DetailsSubcontext.LIVE) {
      this.store
        .select(selectChosenAsset)
        .pipe(first())
        .subscribe(selectedAsset => {
          if (selectedAsset) {
            this.setSelectedAsset(selectedAsset);
          }
        });
    }
  }

  setViewFiltersOpen(filtersOpen: boolean) {
    this.store.dispatch(setViewFiltersOpen({ filtersOpen }));
  }

  setViewPaneOpen(paneOpen: boolean) {
    this.store.dispatch(setViewPaneOpen({ paneOpen }));
  }

  fetchNearbyAssets(selectedAsset: ViewableAsset) {
    this.store.dispatch(loadNearbyAssets({ selectedAsset }));
  }

  clearNearbyAssets() {
    this.store.dispatch(clearNearbyAssets());
  }

  getRecentPath() {
    return this.store.select(selectRecentPath);
  }

  setRecentPath(selectedAsset: ViewableAsset) {
    this.store.dispatch(loadRecentPath({ selectedAsset }));
  }

  clearRecentPath() {
    this.store.dispatch(clearRecentPath());
  }

  clearRecentPathSelectedSegment() {
    this.store.dispatch(clearRecentPathSelectedSegment());
  }

  setRecentPathStartTime(startTime: Date) {
    this.store.dispatch(setRecentPathStartTime({ startTime }));

    this.store
      .select(selectChosenAsset)
      .pipe(first())
      .subscribe(selectedAsset => {
        if (selectedAsset) {
          this.setRecentPath(selectedAsset);
        }
      });
  }

  getRecentPathLoadState() {
    return this.store.select(selectRecentPathLoadState);
  }

  getRecentPathStartTime() {
    return this.store.select(selectRecentPathStartTime);
  }

  getRecentPathTrips() {
    return this.store.select(selectPathTrips);
  }

  getRecentPathSummary() {
    return this.store.select(selectPathSummary);
  }

  getRecentPathSelectedSegment() {
    return this.store.select(selectSelectedPathSegment);
  }

  setRecentPathSelectedSegment(segment) {
    this.store.dispatch(setRecentPathSelectedSegment({ segment }));
  }

  // ===== Store Selectors =====

  // ZTT-3664 TODO: search component will be the last thing dependent on these two methods - deprecate oncee refactored
  //----------------------------------------------
  getAllFilters(): Observable<OldFiltersState> {
    return this.store.select(selectAllFilters);
  }

  getCurrentFilter(): Observable<Filter> {
    return this.store.select(selectCurrentFilter);
  }
  //----------------------------------------------

  getAssetCount(): Observable<number> {
    return this.store.select(selectAssetCount);
  }

  getAllAssets(): Observable<ViewableAsset[]> {
    return this.store.select(selectAllAssets);
  }

  getAssetsLoadState(): Observable<ResourceLoadState> {
    return this.store.select(selectAssetsLoadState);
  }
  getSearchLoadState(): Observable<ResourceLoadState> {
    return this.store.select(selectPlacesLoadState);
  }

  setAutoSearchResult(params: AutoSuggestRequest) {
    this.store.dispatch(loadPlacesResult({ params }));
  }

  clearSearchResult() {
    this.store.dispatch(clearPlacesResult());
  }
  getAutoSearchResult(): Observable<AutoSuggestionResponse> {
    return this.store.select(selectPlacesResults);
  }

  getCompaniesLoadState(): Observable<ResourceLoadState> {
    return this.store.select(selectCompaniesLoadingState);
  }

  getNearbyAssets(): Observable<ViewableAsset[]> {
    return this.store.select(selectNearbyAssets);
  }

  getNearbyAssetsLoadState(): Observable<ResourceLoadState> {
    return this.store.select(selectNearbyAssetsLoadState);
  }

  getNearbyAssetsEnabled(): Observable<boolean> {
    return this.store.select(selectNearbyAssetsEnabled);
  }

  getCompaniesSearchFilterOptions(): Observable<DropdownOption[]> {
    return this.store.select(selectCompaniesSearchFilterOptions);
  }

  getDivisionsSearchFilterOptions(): Observable<DropdownOption[]> {
    return this.store.select(selectDivisionsSearchFilterOptions);
  }

  getFilterChipLabels() {
    return this.store.select(selectFilterChipLabels);
  }

  getIsAssetSelected() {
    return this.store.select(selectIsAssetSelected);
  }

  getLocationsOptionList(): Observable<DropdownOption[]> {
    return this.store.select(selectLocationsSearchFilterOptions);
  }

  getReadCompaniesPerm() {
    return this.store.select(selectReadCompaniesPerm);
  }

  getUserPermissions() {
    return this.store.select(selectAllPermissions);
  }

  getSelectedAsset(): Observable<ViewableAsset> {
    return this.store.select(selectChosenAsset);
  }

  getSelectViewContext() {
    return this.store.select(selectViewContext);
  }

  getSelectedViewPaneOpen() {
    return this.store.select(selectViewPaneOpen);
  }

  getSelectedViewSubContext() {
    return this.store.select(selectViewSubContext);
  }

  setLimitedUserCompanyAndDivisions(params: { companyId: string; divisions: string[] }) {
    return this.store.dispatch(setLimitedUserCompanyAndDivisions(params));
  }

  getShowFilterChipsForLimitedUser() {
    return this.store.select(selectShowFilterChipsForLimitedUser);
  }

  getLocationsForLimitedUser(locations: string[]) {
    this.store.dispatch(setLocationsForLimitedUser({ locations }));
  }

  clearLocations() {
    this.store.dispatch(clearSearchFiltersLocations());
  }

  // currently used in app component to fetch assets on company switch for Zonar/multicompany user
  setAssets(assetsParams: AssetsParams) {
    this.store.dispatch(loadAssets({ assetsParams }));
  }

  // when user switches between companies, dispatches current company to state
  setCurrentCompany(company: EntityResource) {
    this.store.dispatch(setCurrentCompany({ company }));
  }

  getAssetParams() {
    return this.store.select(selectAssetParams);
  }

  getReverseGeocode() {
    return this.store.select(selectReverseGeocode);
  }
  getReverseGeocodeStatus() {
    return this.store.select(selectReverseGeocodeStatus);
  }
  getReverseGeocodeForPoint(lat, lon) {
    return this.geocode.getReverseGeocode(lat, lon);
  }

  getAssetsError(): Observable<any> {
    return this.store.select(selectAssetsLoadError);
  }

  setAssetsError(assetsLoadError = true as any) {
    this.store.dispatch(loadAssetsFailure({ assetsLoadError }));
  }

  setCompaniesError(error?) {
    let errorToSet;
    if (!error) {
      errorToSet = {
        error: {
          error: {
            message: 'default companies error message sent manually'
          }
        }
      };
    } else {
      errorToSet = error;
    }
    this.store.dispatch(getCompaniesFailure(errorToSet));
  }

  toggleIo(i: number, isDisplayed: boolean): void {
    this.leafletService.toggleIo(i, isDisplayed);

    this.getIsMobile()
      .pipe(first())
      .subscribe(isMobile => {
        if (isMobile && isDisplayed) {
          this.setViewContext(ViewContext.SPLIT_VIEW);
        }
      });
  }

  setSelectedAssetSuccess(asset: Asset) {
    this.store.dispatch(loadSelectedAssetSuccess({ asset }));
  }

  setSelectedAssetError(failure: any) {
    this.store.dispatch(loadSelectedAssetFailure({ failure }));
  }

  clearSelectedAsset() {
    this.leafletService.clearSelectedAsset();
    return this.store.dispatch(clearSelectedAsset());
  }
}
