<div id="map-options-menu">
  <div id="classic-layer-selector"
    [class.border-selected]="classicLayerSelected"
    class="classic"
    (click)="onLayerClick('NORMAL_DAY')">
      <span>
        {{ translateService.get(translations.map.layersControl.classicMap) | async | translate }}
      </span>
    
  </div>

  <div id="satellite-layer-selector"
    [class.border-selected]="satelliteLayerSelected"
    class="satellite"
    (click)="onLayerClick('HYBRID_DAY')">
      <span>
        {{ translateService.get(translations.map.layersControl.satellite) | async | translate }}
      </span>
  </div>
  <section *ngIf="checkboxesVisible" class="checkboxes">
    <ul>
      <li><mat-checkbox class="cypress-cluster-toggle" [checked]="clusterEnabled" (change)="onClusterAssetsClick($event)">
        {{ translateService.get(translations.map.checkboxOptions.clusterAssets) | async | translate }}
      </mat-checkbox></li>
      <li><mat-checkbox class="cypress-incident-toggle" [checked]="incidentsEnabled" (change)="onRoadIncidentsClick($event)">
        {{ translateService.get(translations.map.checkboxOptions.roadIncidents) | async | translate }}
      </mat-checkbox></li>
      <li><mat-checkbox class="cypress-traffic-toggle" [checked]="trafficEnabled" (change)="onTrafficClick($event)">
        {{ translateService.get(translations.map.checkboxOptions.traffic) | async | translate }}
      </mat-checkbox></li>
      <li><mat-checkbox id="pendo_gtcx_zone_checkbox" class="cypress-show-zones-toggle" [checked]="zonesEnabled" (change)="onShowZonesClick($event)">
        {{ translateService.get(translations.map.checkboxOptions.showZones) | async | translate }}
      </mat-checkbox></li>
    </ul>
  </section>
</div>
