import {
  ChangeDetectorRef,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  OnDestroy,
  Output
} from '@angular/core';
import { Control, ControlPosition, DomUtil, Map } from 'leaflet';
import { Observable, Subject, tap } from 'rxjs';
import { map, takeUntil, mergeMap, distinctUntilChanged } from 'rxjs/operators';

import { AutoSuggestionItem, AutoSuggestResult } from '@app/modules/location/models/auto-suggestion.model';
import { LocationFacade } from '@app/modules/location/facade/location.facade';
import { ResourceLoadState } from '@app/store/filters/models/resource-load.state';
import { TranslateService } from '@zonar-ui/i18n';
import { Translations } from '@app/core/services/i18n/translations.service';
import { ZonesService } from '@app/modules/zones/services/zones.service';
import { CurrentCompanyService } from '@app/services/current-company.service';
import { Zone } from '@app/modules/zones/zones.model';
import { LeafletZoneService } from '@app/modules/zones/services/leaflet-zone.service';

const pois = 'pois';
const addresses = 'addresses';

@Component({
  selector: 'app-search-control',
  templateUrl: './search-control.component.html',
  styleUrls: ['./search-control.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SearchControlComponent implements OnInit, OnDestroy {
  private _map: Map;
  searchControl: Control;
  showSearchField: boolean = false;
  pois: AutoSuggestionItem[];
  addresses: AutoSuggestionItem[];
  nonAddressResults = ['place', 'categoryQuery', 'chainQuery'];
  onDestroy$ = new Subject<void>();
  skeletonMaxCount = 5;
  searchKey: string;
  loadSuccessful: string = ResourceLoadState.LOAD_SUCCESSFUL;
  loadSuccessFail: string = ResourceLoadState.LOAD_FAILURE;
  loading: string = ResourceLoadState.LOADING;
  initial: string = ResourceLoadState.INITIAL;
  zones: Zone[];
  zonesLoaded: boolean = false;

  @Input() position: ControlPosition;
  @Input()
  set map(map: Map) {
    if (map) {
      this._map = map;
      const SearchControl = Control.extend({
        onAdd(map: Map) {
          return DomUtil.get('searchControl');
        },
        onRemove(map: Map) {}
      });

      this.searchControl = new SearchControl({
        position: this.position
      }).addTo(map);
    }
  }
  get map(): Map {
    return this._map;
  }

  @Output() zoneResultClicked: EventEmitter<any> = new EventEmitter();

  constructor(
    private locationFacade: LocationFacade,
    public translateService: TranslateService,
    public translations: Translations,
    private zonesService: ZonesService,
    private currentCompany: CurrentCompanyService,
    private cdr: ChangeDetectorRef,
    private leafletZoneService: LeafletZoneService
  ) {}

  ngOnInit(): void {
    this.getAutoSuggestions().subscribe((searchresult: AutoSuggestResult) => {
      this.pois = this.formatPois(searchresult.pois);
      this.addresses = searchresult.addresses;
    });
  }

  ngOnDestroy() {
    this._map?.removeControl(this.searchControl);
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  formatPois(pois: AutoSuggestionItem[]): AutoSuggestionItem[] {
    return pois?.map(item => {
      const title = item.address.label.split(',').splice(0, 3);
      return { ...item, title: title.join(',').replace(/\d+[A-z]?\s/gm, '') };
    });
  }

  loadComplete(): Observable<string> {
    return this.locationFacade.getSearchLoadState().pipe(
      map(loadState => {
        return loadState;
      }),
      takeUntil(this.onDestroy$)
    );
  }
  toggleSearchField() {
    this.showSearchField = !this.showSearchField;
    this.searchKey = '';
    this.resetResults();
    this.leafletZoneService.unHighlightSelectedZone();
  }

  onSearch(event) {
    const query = event.target.value as string;
    if (query.length < 2) {
      this.resetResults();
      return;
    }
    const mapCenter = this.locationFacade.getMapCenter();
    const params = {
      q: query,
      at: `${mapCenter.lat},${mapCenter.lng}`
    };
    this.locationFacade.setAutoSearchResult(params);
    this.getZonesSearchResult(query);
  }

  getZones(searchQuery: string) {
    return this.currentCompany.getCurrentCompanyId().pipe(
      tap(() => {
        this.zonesLoaded = false;
        this.zones = [];
        this.cdr.detectChanges();
      }),
      mergeMap(companyId => {
        const params = {
          companyId,
          per_page: 100,
          q: `name:${searchQuery}`,
          sort: 'name'
        };
        return this.zonesService.getZonesBySearchTerms(params).pipe(distinctUntilChanged());
      })
    );
  }

  getZonesSearchResult(searchQuery: string) {
    this.getZones(searchQuery)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(results => {
        this.zonesLoaded = true;
        this.zones = results.slice(0, 5) as Zone[];
        this.cdr.detectChanges();
      });
  }

  goToLocation(suggestionItem: AutoSuggestionItem, address = true) {
    this.searchKey = address ? suggestionItem?.address?.label : suggestionItem?.title;
    this.clearSearchResult();
    this.locationFacade.showSelectedLocation(suggestionItem.position);
  }

  goToZone(zone: Zone) {
    this.searchKey = zone.name;
    this.zoneResultClicked.emit();
    this.locationFacade.zoomToZone(zone);
    this.clearSearchResult();

    this.leafletZoneService.highlightSelectedZone(zone, this.map);
  }

  getAutoSuggestions() {
    return this.locationFacade.getAutoSearchResult().pipe(
      map(response => {
        const searchResult = {};
        searchResult[pois] = response?.items.filter(item => item.resultType == 'place');
        searchResult[addresses] = response?.items.filter(item => !this.nonAddressResults.includes(item.resultType));
        return searchResult;
      }),
      takeUntil(this.onDestroy$)
    );
  }

  resetResults() {
    this.clearSearchResult();
    this.pois = [];
    this.addresses = [];
    this.zones = [];
  }
  clearSearchResult() {
    this.locationFacade.clearSearchResult();
  }
}
